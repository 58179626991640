/* src/index.css */

@font-face {
  font-family: 'NexaBold';
  src: url('./assets/fonts/NEXA-BOLD.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Disable scrolling */
  width: 100%;
  height: 100%;
  font-family: 'NexaBold', sans-serif; /* Ensure your custom font is used */
  background-color: #000; /* Matte black background */
  color: #fff; /* Default text color */
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button, input, textarea {
  font-family: inherit; /* Inherit the body font, in this case, NexaBold */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'NexaBold', sans-serif;
}
